import { useEffect, useState } from "react";

import Link from "next/link";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Avatar from "./avatar";
import EnrollmentModal from "./enrollmentmodal";
import PriceInfoPopover from "./priceinfopopover";
import WinnerExperienceGain from "./winnerexperiencegain";
import PlacesLeftInfo from "./placesleftinfo";
import { getParticipantAvatarClassName } from "../lib/content";
import GamePlace from "./gameplace";
import PlayerPeerRating from "./playerpeerrating";
import ConversionInfo from "./conversioninfo";
import ScenarioRating from "./scenariorating";
import HostGrade from "./hostgrade";
import MafiaGameSelector from "./mafiagameselector";
import { fetchMafiaGame } from "../lib/api/mafia";
import GameExtraInfoPoints from "./gameextrainfopoints";
import { filterParticipantListByTeamId, getGameTeamFromList } from "../lib/teams";
import GameTeamsSelector from "./gameteamselector";
import GameCharacteristics from "./gamecharacteristics";
import GameLevel from "./gamelevel";
import ThumbnailPicture from "./thumbnailpicture";
import { isMeet4USite } from "../lib/coreutils";

function ScheduleCardParticipantsTable({ game, participantsToShow, mafiaParticipants }) {
    const conversionInfo = game.conversion_info;

    return (
        <div className={"table-responsive " + (mafiaParticipants ? "table-sticky-column-1" : "")}>
            <table className="players-table mt-3">
                <thead>
                    <tr>
                        <th></th>
                        {mafiaParticipants ? (
                            <>
                                <th>Жив</th>
                                <th>База</th>
                                <th>Бонус</th>
                                <th>Итого</th>
                                <th>Очков</th>
                            </>
                        ) : (
                            <>
                                {game.is_ended ? (
                                    <>
                                        {game.game_type.has_place_distribution && <th>Место</th>}
                                        <th>Побед</th>
                                        <th>Очки</th>
                                    </>
                                ) : (
                                    <>
                                        <th>Игры</th>
                                        <th>Побед</th>
                                    </>
                                )}
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {participantsToShow.map((participant) => (
                        <tr key={participant.player.id}>
                            <td>
                                <div className="players-table__participant">
                                    <Avatar
                                        player={participant.player}
                                        size={66}
                                        className={
                                            "players-table__participant__avatar" +
                                            getParticipantAvatarClassName(participant)
                                        }
                                    />
                                    <div>
                                        <span className="text-nowrap">
                                            <Link href={participant.player.url}>
                                                <a className="players-table__participant__name">
                                                    {participant.player.name}
                                                </a>
                                            </Link>{" "}
                                            {participant.player.mafia_nick && (
                                                <>
                                                    <br />
                                                    {participant.player.mafia_nick}{" "}
                                                </>
                                            )}
                                            {mafiaParticipants ? null : (
                                                <>
                                                    {!isMeet4USite() && <>[{participant.player.level}]</>}
                                                    <PlayerPeerRating player={participant.player} />
                                                </>
                                            )}
                                        </span>
                                        {mafiaParticipants ? (
                                            <div className="players-table__participant__description">
                                                {mafiaParticipants[participant.player.id]?.role_name || "-"}
                                            </div>
                                        ) : (
                                            <>
                                                {conversionInfo &&
                                                    conversionInfo.conversion_participants[participant.player.id] && (
                                                        <div className="text-muted small">
                                                            {
                                                                conversionInfo.conversion_participants[
                                                                    participant.player.id
                                                                ]
                                                            }
                                                        </div>
                                                    )}
                                                <div className="players-table__participant__description">
                                                    {participant.player.description}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </td>
                            {mafiaParticipants ? (
                                <>
                                    {mafiaParticipants[participant.player.id]?.number ? (
                                        <>
                                            <td>{mafiaParticipants[participant.player.id].is_alive ? "" : "X"}</td>
                                            <td>{mafiaParticipants[participant.player.id].base_score}</td>
                                            <td>{mafiaParticipants[participant.player.id].bonus_score}</td>
                                            <td>{mafiaParticipants[participant.player.id].total_score}</td>
                                            <td
                                                className={
                                                    mafiaParticipants[participant.player.id].are_points_counted
                                                        ? "text-bold"
                                                        : ""
                                                }
                                            >
                                                {mafiaParticipants[participant.player.id].total_points}
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {game.is_ended ? (
                                        <>
                                            {game.game_type.has_place_distribution && <td>{participant.place}</td>}
                                            <td>{participant.num_wins_total}</td>
                                            <td>{participant.experience_gained}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{participant.num_games_total}</td>
                                            <td>{participant.num_wins_total}</td>
                                        </>
                                    )}
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default function ScheduleCard({
    game,
    gameDescription,
    mafiaGames,
    gameTeams,
    forcedExpand,
    expandedByDefault,
    isEnrollment,
    isArchive,
    isExpandableParticipantsTable,
    isGamesList,
    showPlacesLeftInfo,
    showParticipantsTable,
    showFormula,
    onEnrollment,
}) {
    const [expanded, setExpanded] = useState(forcedExpand || expandedByDefault);
    const [featureCollapsed, setFeatureCollapsed] = useState(!showParticipantsTable);
    const [expandedParticipantsTable, setExpandedParticipantsTable] = useState(false);
    const [showEnrollment, setShowEnrollment] = useState(false);
    const [selectedGameTeamId, setSelectedGameTeamId] = useState("all");

    const [selectedMafiaGame, setSelectedMafiaGame] = useState(0);
    const [mafiaGame, setMafiaGame] = useState(null);

    const onMafiaGameSelect = (gameNumber) => {
        setSelectedMafiaGame(gameNumber);
    };

    const onSelectGameTeamId = (gameTeamId) => {
        setSelectedGameTeamId(gameTeamId);
        if (gameTeamId == "new") {
            setShowEnrollment(true);
        }
    };

    const showEnrollmentModal = (event) => {
        event.preventDefault();
        if (isGamesList && game?.with_teams) {
            window.location.href = game.url;
            return;
        }
        setShowEnrollment(true);
    };

    useEffect(() => {
        const loadSelectedMafiaGame = async () => {
            if (parseInt(selectedMafiaGame)) {
                const mafiaGame = await fetchMafiaGame(game.id, selectedMafiaGame);
                if (mafiaGame.number) {
                    setMafiaGame(mafiaGame);
                }
            } else {
                setMafiaGame(null);
            }
        };
        loadSelectedMafiaGame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMafiaGame]);

    const toggleParticipantsTable = (e) => {
        e.preventDefault();
        setExpandedParticipantsTable((prevState) => !prevState);
    };

    const priceInfoPopover = <PriceInfoPopover game={game} />;

    let linkToMafiaDetails = null;
    if (mafiaGames && mafiaGames.length > 0) {
        const gameUrl = new URL(game.url).pathname;
        linkToMafiaDetails = (
            <Link href={`${gameUrl}mafia/${mafiaGames[0]}/`}>
                <a className="nav-link text-decoration-none">Детальная аналитика</a>
            </Link>
        );
    }

    let mafiaParticipants = null;
    if (mafiaGame) {
        mafiaParticipants = {};
        mafiaGame.participants.forEach((participant) => {
            mafiaParticipants[participant.player.id] = participant;
        });
    }

    const gameTeam = getGameTeamFromList(gameTeams, selectedGameTeamId);
    const participantsToShow = filterParticipantListByTeamId(game.participant_list, selectedGameTeamId);

    return (
        <>
            <div className="schedule__card schedule__card--enrollment">
                {game.participant_list.length > 0 && (
                    <div className="schedule__card__participant-count">
                        {game.participant_list.length}
                        {game.max_participants ? `/${game.max_participants}` : ""}
                    </div>
                )}
                <h3 className="schedule__card__date">
                    <a onClick={() => setExpanded(!expanded)}>
                        <span>{game.start_time_short}</span>
                    </a>
                </h3>
                {expanded && (
                    <div className="schedule__card__details">
                        <div className="text-muted mb-1 d-flex justify-content-center align-items-center">
                            <div>
                                Игра:{" "}
                                <Link href={game.url}>
                                    <a className="text-decoration-none">{game.game_type.name}</a>
                                </Link>
                            </div>
                            <GameLevel gameLevel={game.game_level} className="ms-2" />
                            <HostGrade hostGrade={game.host_grade} className="ms-2 d-none" levelOnly />
                        </div>
                        {!isMeet4USite() && (
                            <>
                                <GameCharacteristics game={game} className="text-body" />
                                {game.thomases_scenario && (
                                    <div className="text-primary">
                                        Сценарий &laquo;{game.thomases_scenario.name}&raquo;
                                        <ScenarioRating scenario={game.thomases_scenario} />
                                    </div>
                                )}
                                {gameDescription && (
                                    <div className="schedule__card__game-feature text-body my-2">
                                        {gameDescription.description_mobile}{" "}
                                        <Link href={gameDescription.more_link}>
                                            <a className="text-primary">Подробнее</a>
                                        </Link>
                                    </div>
                                )}
                                {game.feature && (
                                    <div
                                        className={
                                            "schedule__card__game-feature" +
                                            (featureCollapsed ? " schedule__card__game-feature--collapsed" : "")
                                        }
                                        onClick={() => setFeatureCollapsed(!featureCollapsed)}
                                    >
                                        {game.feature}
                                    </div>
                                )}
                            </>
                        )}
                        {game.place && (
                            <div className="schedule__card__address my-2">
                                <span className="icon-geo-point me-2"></span>
                                <GamePlace place={game.place} />
                            </div>
                        )}
                        {game.host && (
                            <div className="schedule__card__host mb-2">
                                <Avatar player={game.host} size={66} />
                                <div className="ms-2 text-start">
                                    <strong>{game.host.name}</strong> / ведущий
                                    <PlayerPeerRating
                                        player={game.host}
                                        noDecoration
                                        className="schedule__card__host-grade d-block"
                                    />
                                    {game.club && (
                                        <div className="schedule__card__host__club d-flex align-items-center my-1">
                                            <ThumbnailPicture
                                                thumbnail={game.club.thumbnail}
                                                width={20}
                                                height={20}
                                                alt={game.club.name}
                                            />
                                            <div className="ms-1 text-muted">{game.club.name}</div>
                                        </div>
                                    )}
                                    {isEnrollment && game.host.feature && (
                                        <div className="schedule__card__host-feature">{game.host.feature}</div>
                                    )}
                                </div>
                            </div>
                        )}
                        {game.organizer && (
                            <div className="schedule__card__host mb-2">
                                <Avatar player={game.organizer} size={66} />
                                <div className="ms-2 text-start">
                                    <strong>{game.organizer.name}</strong> / организатор
                                    <PlayerPeerRating
                                        player={game.organizer}
                                        noDecoration
                                        className="schedule__card__host-grade d-block"
                                    />
                                </div>
                            </div>
                        )}

                        <ConversionInfo conversionInfo={game.conversion_info} className="small text-muted" />

                        {game.is_cancelled && <div className="alert alert-danger my-4">Игра была отменена.</div>}

                        {game.game_price_amount && !game.is_cancelled && (
                            <div className="schedule__card__price">
                                Цена: {game.game_price_amount}{" "}
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={priceInfoPopover}>
                                    <a tabIndex="0" className="info-button" role="button">
                                        ?
                                    </a>
                                </OverlayTrigger>
                            </div>
                        )}
                        <GameExtraInfoPoints game={game} className="my-1" showFormula={showFormula} />

                        {(!game.is_ended || !showParticipantsTable) && (
                            <WinnerExperienceGain game={game} className="my-3" />
                        )}

                        <MafiaGameSelector
                            mafiaGames={mafiaGames}
                            selectedMafiaGame={selectedMafiaGame}
                            withTotal
                            appendComponent={linkToMafiaDetails}
                            onSelect={onMafiaGameSelect}
                            className="mt-2"
                        />

                        {mafiaGame && (
                            <div className="text-muted text-center mt-2 mb-0">
                                1 балл = <b>{mafiaGame.points_per_score}</b> очков
                            </div>
                        )}

                        {game.with_teams && (
                            <GameTeamsSelector
                                gameTeams={gameTeams}
                                selectedGameTeamId={selectedGameTeamId}
                                onSelectGameTeamId={onSelectGameTeamId}
                                className="mt-3 mb-3"
                            />
                        )}

                        {game.participant_list.length > 0 && (
                            <>
                                <div className="mt-3">
                                    {isArchive ? (
                                        "Участники:"
                                    ) : (
                                        <>
                                            {game.teams_info && game.teams_info.num_teams > 1
                                                ? `Уже ${game.teams_info.num_teams_str}`
                                                : "Уже идут:"}
                                        </>
                                    )}
                                </div>
                                {showParticipantsTable || expandedParticipantsTable ? (
                                    <ScheduleCardParticipantsTable
                                        game={game}
                                        participantsToShow={participantsToShow}
                                        mafiaParticipants={mafiaParticipants}
                                    />
                                ) : (
                                    <div className="schedule__participants">
                                        {participantsToShow.map((participant) => (
                                            <Avatar
                                                player={participant.player}
                                                size={66}
                                                className={getParticipantAvatarClassName(participant)}
                                                key={participant.player.id}
                                            />
                                        ))}{" "}
                                    </div>
                                )}
                                {isExpandableParticipantsTable && !showParticipantsTable && (
                                    <div>
                                        <button
                                            type="button"
                                            className={
                                                "schedule__toggle-participants-table" +
                                                (expandedParticipantsTable
                                                    ? " schedule__toggle-participants-table--expanded"
                                                    : "")
                                            }
                                            onClick={toggleParticipantsTable}
                                        >
                                            {expandedParticipantsTable ? "Свернуть" : "Развернуть"}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}

                        {!game.is_cancelled && (
                            <>
                                {showPlacesLeftInfo && <PlacesLeftInfo game={game} className="my-3" />}
                                {!isArchive && onEnrollment && (
                                    <button
                                        className={
                                            "btn schedule__card__btn-enroll " +
                                            (game.is_enrollment_prohibited && !game.is_waiting_list
                                                ? "btn-orange"
                                                : "btn-primary")
                                        }
                                        onClick={showEnrollmentModal}
                                    >
                                        {game.is_waiting_list ? "В лист ожидания" : "Забронировать место"}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
            {showEnrollment && (
                <EnrollmentModal
                    game={game}
                    gameTeam={gameTeam}
                    onHide={() => setShowEnrollment(false)}
                    onEnrollment={onEnrollment}
                />
            )}
        </>
    );
}
